import { TTypeProcesses } from "constants/types"
import TEST from "data/test"
import logo_mga from 'images/logo_mga.png'
import { IStudent } from "interfaces"
import jsPDF from "jspdf"

export const generateDataTablePDF = (student: IStudent) => {
  const answers: number[] = JSON.parse(student.answers);
  var result: { [key: string]: string }[] = [];

  TEST?.map((item, index) => {
    const returnInteres = (value: number) => {
      if (value >= 24 && value <= 30) {
        return 'Interés alto'
      }

      if (value >= 17 && value <= 23) {
        return 'Interés medio'
      }

      return 'Desinterés'
    }

    const data = {
      CAMPO: `Campo ${item?.carrer}`,
      PUNTAJE: answers?.[index] ? answers[index].toString() : '0',
      INTERESES: answers?.[index] ? returnInteres(answers?.[index]) : 'Desinterés',
    };

    result.push(Object.assign({}, data));
  })

  return result;
};

const createHeaders = (keys: string[]) => {
  var result = [];
  for (var i = 0; i < keys.length; i += 1) {
    result.push({
      id: keys[i],
      name: keys[i],
      prompt: keys[i],
      width: i == 0 ? 130 : i == 1 ? 30 : 40,
      align: i == 0 ? 'left' : "center",
      padding: 0,
    });
  }

  return result;
}

export const headersPDF: any = () => {
  return createHeaders([
    "CAMPO",
    "PUNTAJE",
    "INTERESES",
  ])
};

const returnYears = (date: string) => {
  const today = new Date()
  const birthDate = new Date(date)
  let years = today.getFullYear() - birthDate.getFullYear()
  const months = today.getMonth() - birthDate.getMonth()
  if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
    years--
  }
  return years
}

export const bodyPDF = (doc: jsPDF, student: IStudent, type: TTypeProcesses) => {
  const answers: number[] = JSON.parse(student.answers);

  //HEADER
  if (type === 'usat') doc.setFillColor('#ef672d');
  if (type === 'mga' || type === 'self-pay' || type === 'utp') doc.setFillColor('#fac1a6');

  doc.ellipse(0, -10, 35, 35, "F");

  doc.setFillColor('#f2f2f2');
  doc.ellipse(210, -10, 35, 35, "F");

  if (type === 'usat') doc.addImage('https://consultoriomga.com/test-vocacional-react/img/logo_usat.jpg', 'JPEG', 155, 13, 25, 22)
  if (['mga', 'self-pay', 'utp'].includes(type)) doc.addImage(logo_mga, 'PNG', 155, 13, 30, 27)

  //FOOTER
  if (type === 'usat') doc.setFillColor('#ef672d');
  if (type === 'mga' || type === 'self-pay') doc.setFillColor('#fac1a6');
  doc.ellipse(210, 310, 35, 35, "F");

  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.setTextColor('#ef672d');
  doc.text(`consultoriomga.com`, 105, 291, {
    align: 'center',
  });

  //BODY 
  doc.setFillColor('#ef672d');
  doc.roundedRect(27, 17, 58, 7, 1, 1, "F");

  doc.setFillColor('#ef672d');
  doc.ellipse(85, 20.5, 3.5, 3.5, "F");

  doc.setFont("helvetica", "bold");
  doc.setTextColor('#FFF');
  doc.setFontSize(11);
  doc.text(`IPPR`, 29, 22);

  doc.setFontSize(8);
  doc.setTextColor('#6c6969');
  doc.text(`Inventario de intereses y preferencias profesionales que evalúa diferentes aspectos agrupados en 15 campos profesionales`
    , 29, 28, { maxWidth: 85, align: 'justify' });


  doc.setFont("helvetica", "bold");
  doc.setTextColor('#ef672d');
  doc.setFontSize(11);
  doc.text(`${student.names} ${student.lastNames}`, 29, 45);

  doc.setFontSize(10);

  doc.setFont("helvetica", "normal");
  doc.setTextColor('#ef672d');
  doc.text(`DNI:`, 29, 50);
  doc.setTextColor('#6c6969');
  doc.text(`${student.dni}`, 37, 50);

  doc.setTextColor('#ef672d');
  doc.text(`Edad:`, 29, 55);
  doc.setTextColor('#6c6969');
  doc.text(`${returnYears(student.dateOfBirth)} años`, 40, 55);

  doc.setTextColor('#ef672d');
  doc.text(`Correo:`, 29, 60);
  doc.setTextColor('#6c6969');
  doc.text(`${student.email}`, 43, 60);

  // doc.setTextColor('#ef672d');
  // doc.text(`Carrera:`, 29, 65);
  // doc.setTextColor('#6c6969');
  // doc.text(`${student.career}`, 44, 65);

  doc.setTextColor('#ef672d');
  doc.text(`Proceso:`, 29, 65);
  doc.setTextColor('#6c6969');
  doc.text(`Test IPP-R Adaptado por MGA`, 45, 65);

  doc.setTextColor('#ef672d');
  doc.text(`Fecha:`, 29, 70);
  doc.setTextColor('#6c6969');
  doc.text(`${new Date().toLocaleString().split(',')[0]}`, 43, 70);

  if(type === 'utp'){
    doc.setTextColor('#ef672d');
    doc.text(`Colegio:`, 29, 75);
    doc.setTextColor('#6c6969');
    doc.text(`${student.school || '-'}`, 44, 75);
  }

  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.setTextColor('#ef672d');
  doc.text(`RESULTADOS`, 105, 90, {
    align: 'center',
  });

  doc.setDrawColor('#ffd6b1');
  doc.setFontSize(11);
  doc.setTextColor('#6c6969');
  doc.table(29, 97, generateDataTablePDF(student), headersPDF(), {
    fontSize: 11,
    printHeaders: true,
    headerBackgroundColor: '#ef672d',
    headerTextColor: '#FFFFFF',
    padding: 2,
    cellStart: function (e, doc) {
      if (e.col = 1) {
        const value = Number(e.data);

        if (value >= 17 && value <= 23) {
          doc.setTextColor('orange');
        }

        if (value >= 24 && value <= 30) {
          doc.setTextColor('green');
        }

        if (value <= 16) {
          doc.setTextColor('red');
        }

        if (!value && value !== 0) {
          doc.setTextColor('#6c6969');
        }
      }
    }
  });

  doc.setDrawColor('#6c6969');
  doc.roundedRect(75, 240, 60, 24, 2, 2);

  doc.setFont("helvetica", "normal");
  doc.setTextColor('#6c6969');
  doc.text(`0-16`, 82, 247);
  doc.setFillColor('red');
  doc.ellipse(98, 246, 1.5, 1.5, "F");
  doc.text(`DESINTERÉS`, 102, 247);

  doc.setFont("helvetica", "normal");
  doc.setTextColor('#6c6969');
  doc.text(`17-23`, 82, 253);
  doc.setFillColor('orange');
  doc.ellipse(98, 252, 1.5, 1.5, "F");
  doc.text(`Interés MEDIO`, 102, 253);

  doc.setFont("helvetica", "normal");
  doc.setTextColor('#6c6969');
  doc.text(`24-30`, 82, 259);
  doc.setFillColor('green');
  doc.ellipse(98, 258, 1.5, 1.5, "F");
  doc.text(`Interés ALTO`, 102, 259);

  // if (answers?.every(answer => answer < 17)) {
  //   doc.addPage();

  //   //HEADER
  //   if (type === 'usat') doc.setFillColor('#ef672d');
  //   if (type === 'mga' || type === 'self-pay' || type === 'utp') doc.setFillColor('#fac1a6');
  //   doc.ellipse(0, -10, 35, 35, "F");

  //   doc.setFillColor('#f2f2f2');
  //   doc.ellipse(210, -10, 35, 35, "F");

  //   if (type === 'usat') doc.addImage('https://consultoriomga.com/test-vocacional-react/img/logo_usat.jpg', 'JPEG', 155, 13, 25, 22)
  //   if (['mga', 'self-pay', 'utp'].includes(type)) doc.addImage(logo_mga, 'PNG', 155, 13, 30, 27)
  
  //   //FOOTER
  //   if (type === 'usat') doc.setFillColor('#ef672d');
  //   if (type === 'mga' || type === 'self-pay' || type === 'utp') doc.setFillColor('#fac1a6');
  //   doc.ellipse(210, 310, 35, 35, "F");

  //   doc.setFontSize(12);
  //   doc.setFont("helvetica", "bold");
  //   doc.setTextColor('#ef672d');
  //   doc.text(`consultoriomga.com`, 105, 291, {
  //     align: 'center',
  //   });

  //   doc.setFontSize(16);
  //   doc.setFont("helvetica", "bold");
  //   doc.setTextColor('#6c6969');
  //   doc.text('No se encontraron resultados', 30, 50)
  //   return
  // }

  const RESULTS: any = []

  answers?.map((answer, index) => {
    if (answer >= 24 && answer <= 30) {
      RESULTS.push({
        test: TEST[index],
        answer: answer,
        result: 1 // alto
      })
    }

    if (answer >= 17 && answer <= 23) {
      RESULTS.push({
        test: TEST[index],
        answer: answer,
        result: 0 // medio
      })
    }

    if (answer <= 16){
      RESULTS.push({
        test: TEST[index],
        answer: answer,
        result: -1 // bajo
      })
    }
  })

  RESULTS?.sort((a: any, b: any) => {
    if (a.answer > b.answer) {
      return -1;
    }
    if (a.result < b.result) {
      return 1;
    }
    return 0;
  })

  let cant = 3
  const description_high: string = 'REFLEJA CON MÁS EXACTITUD LAS PREFERENCIAS VOCACIONALES DEL EVALUADO(A).'
  const description_medium: string = 'REFLEJA UN POSIBLE INDICADOR DE LAS PREFERENCIAS VOCACIONALES DEL EVALUADO(A).'

  RESULTS?.forEach((result: any, index: number) => {
    if (cant === 3) {
      cant = 0
      doc.addPage();

      //HEADER
      if (type === 'usat') doc.setFillColor('#ef672d');
      if (type === 'mga' || type === 'self-pay' || type === 'utp') doc.setFillColor('#fac1a6');
      doc.ellipse(0, -10, 35, 35, "F");

      doc.setFillColor('#f2f2f2');
      doc.ellipse(210, -10, 35, 35, "F");

      if (type === 'usat') doc.addImage('https://consultoriomga.com/test-vocacional-react/img/logo_usat.jpg', 'JPEG', 155, 13, 25, 22)
      if (['mga', 'self-pay', 'utp'].includes(type)) doc.addImage(logo_mga, 'PNG', 155, 13, 30, 27)

      //FOOTER
      if (type === 'usat') doc.setFillColor('#ef672d');
      if (type === 'mga' || type === 'self-pay' || type === 'utp') doc.setFillColor('#fac1a6');
      doc.ellipse(210, 310, 35, 35, "F");

      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.setTextColor('#ef672d');
      doc.text(`consultoriomga.com`, 105, 291, {
        align: 'center',
      });

      //BODY
      if (index === 0) {
        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");
        doc.setTextColor('#6c6969');
        const description = 'En base a tus respuestas, te mostramos tus RESULTADOS de manera descendente, es decir,'
        const descriptionStrong = 'de las preferencias ALTAS a las preferencias MEDIAS:'
        doc.text(description, 25, 41, { maxWidth: 150, align: 'justify', lineHeightFactor: 1.2 });
        doc.setFont("helvetica", "bold");
        doc.text(descriptionStrong, 25, 46, { maxWidth: 150, align: 'justify', lineHeightFactor: 1.2 });
      }
    }

    // SE ACEPTA 3 RESULTADOS POR PAGINA
    if (cant === 0) {
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.setTextColor('#ef672d');
      doc.text(`Campo ${result.test.carrer}`, 45, 55);

      doc.setFontSize(8);
      doc.setFont("helvetica", "normal");
      doc.setTextColor('black');
      doc.text(`${result.result === 1 ? description_high : description_medium}`, 45, 60);

      doc.setFontSize(9);
      const description = result.test.results[0] + result.test.suggestionsLabel + result.test.suggestions
      doc.setTextColor('#6c6969');
      doc.text(description, 45, 65, { maxWidth: 135, align: 'justify', lineHeightFactor: 1.2 });

      doc.addImage(result.test.icon, 'PNG', 25, 50, 15, 15)

      doc.setFillColor(result.result === 1 ? 'green' : result.result === 0 ? 'orange' : 'red');
      doc.roundedRect(25, 65, 15, 6, 0, 0, 'F');
      doc.setTextColor('white');
      doc.text(`${result.answer}`, 31, 69);
    }

    if (cant === 1) {
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.setTextColor('#ef672d');
      doc.text(`Campo ${result.test.carrer}`, 45, 114);

      doc.setFontSize(8);
      doc.setFont("helvetica", "normal");
      doc.setTextColor('black');
      doc.text(`${result.result === 1 ? description_high : description_medium}`, 45, 120);

      doc.setFontSize(9);
      const description = result.test.results[0] + result.test.suggestionsLabel + result.test.suggestions
      doc.setTextColor('#6c6969');
      doc.text(description, 45, 124, { maxWidth: 135, align: 'justify', lineHeightFactor: 1.2 });

      doc.addImage(result.test.icon, 'PNG', 25, 109, 15, 15)

      doc.setFontSize(9);
      doc.setFillColor(result.result === 1 ? 'green' : result.result === 0 ? 'orange' : 'red');
      doc.roundedRect(25, 124, 15, 6, 0, 0, 'F');
      doc.setTextColor('white');
      doc.text(`${result.answer}`, 31, 128);
    }

    if (cant === 2) {
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.setTextColor('#ef672d');
      doc.text(`Campo ${result.test.carrer}`, 45, 174);

      doc.setFontSize(8);
      doc.setFont("helvetica", "normal");
      doc.setTextColor('black');
      doc.text(`${result.result === 1 ? description_high : description_medium}`, 45, 180);

      doc.setFontSize(9);
      const description = result.test.results[0] + result.test.suggestionsLabel + result.test.suggestions
      doc.setTextColor('#6c6969');
      doc.text(description, 45, 184, { maxWidth: 135, align: 'justify', lineHeightFactor: 1.2 });

      doc.addImage(result.test.icon, 'PNG', 25, 170, 15, 15)

      doc.setFontSize(9);
      doc.setFillColor(result.result === 1 ? 'green' : result.result === 0 ? 'orange' : 'red');
      doc.roundedRect(25, 185, 15, 6, 0, 0, 'F');
      doc.setTextColor('white');
      doc.text(`${result.answer}`, 31, 189);
    }

    cant++
  })

  //FOOTER
  doc.addImage('https://consultoriomga.com/test-vocacional-react/img/mail.png', 'PNG', 25, 254, 4, 4)
  doc.addImage('https://consultoriomga.com/test-vocacional-react/img/whatsapp.png', 'PNG', 25, 259.5, 4, 4)
  doc.addImage('https://consultoriomga.com/test-vocacional-react/img/time.png', 'PNG', 25, 265.5, 4, 4)

  doc.setFontSize(16);
  doc.setFont("helvetica", "bold");
  doc.setTextColor('#ef672d');
  doc.text(`¡Gracias por participar!`, 105, 241, { align: 'center' });

  doc.setFontSize(11);
  doc.setFont("helvetica", "normal");
  doc.setTextColor('#6c6969');
  doc.text(`Si tienes alguna duda respecto a la interpretación de los resultados, contáctanos en:`, 25, 249);

  doc.setFontSize(11);
  doc.textWithLink('consultas@consultoriomga.com', 30, 257, { url: 'mailto:consultas@consultoriomga.com' })

  doc.setFontSize(11);
  doc.textWithLink('987804573', 30, 263, { url: 'https://walink.co/7d2943', target: '_blank' })

  doc.setFontSize(11);
  doc.text(`Lunes a viernes de 9:00am a 6:00pm`, 30, 269);
}